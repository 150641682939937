// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import moment from "moment";

document.addEventListener("DOMContentLoaded", () => {
    const CONTAINER = document.getElementById("eventkeeper");
    const LISTINGS = document.getElementById("eventkeeper-listings");

    if (evtArray !== "undefined" && evtArray.length > 0 && CONTAINER && LISTINGS) {
        evtArray.forEach((event) => {
            const DATE = moment(event[0], "MMM D, YYY");

            event[1] = event[1].replace(/&nbsp;/g, "");

            const MARKUP =
                `<div class="date-text-main-wrapper">
                    <div class="date-wrapper">
                            <a class='datetime-wrapper' href='https://www.eventkeeper.com/mars/xpages/xp_newpopevent.cfm?zeeOrg=CHBOOTH&EventID=${event[3]}' rel='noopener' target='_blank'>
                                <time class="datetime">
                                    <span class="month">${DATE.format("MMM")}</span>
                                    <span class="day">${DATE.format("D")}</span>
                                </time>
                            </a>
                        </p>
                    </div><!--/.date-wrapper-->
                    <div class="text-and-date-wrapper">
                        <a class='title-time' href='https://www.eventkeeper.com/mars/xpages/xp_newpopevent.cfm?zeeOrg=CHBOOTH&EventID=${event[3]}' rel='noopener' target='_blank'>
                            <h5>${event[2]}</h5>
                            <time>${event[1]}</time>
                        </a>
                    </div><!--/.text-and-date-wrapper-->
                </div><!--/.date-text-main-wrapper-->`;

            LISTINGS.innerHTML = LISTINGS.innerHTML + MARKUP;
        });

        CONTAINER.style.display = "block";
    }
});
