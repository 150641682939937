// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import "slick-carousel";

// custom

jQuery(document).ready(function () {
    jQuery(document).on("click", function () {
        jQuery("body").removeClass("mobile-search-main");
    });
    jQuery(".mobile-radio-input, .mobile-menu-search").on("click", function (e) {
        e.stopPropagation();
    });

    jQuery(".mobile-menu-search").on("click", function () {

        jQuery(".radio-input.mobile-radio-input").slideToggle();
    });


    jQuery(".your-class").slick({
        autoplay: true,
        autoplaySpeed: 3000,
    });
});

const equalheightrow = function (container) {
    let currentTallest = 0;
    let currentRowStart = 0;
    const rowDivs = new Array();
    let jQueryel;
    jQuery(container).each(function () {
        jQueryel = jQuery(this);
        jQuery(jQueryel).height("auto");
        const topPostion = jQueryel.offset().top;

        if (currentRowStart != topPostion) {
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = jQueryel.height();
            rowDivs.push(jQueryel);
        } else {
            rowDivs.push(jQueryel);
            currentTallest = (currentTallest < jQueryel.height()) ? (jQueryel.height()) : (currentTallest);
        }
        for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
};

jQuery(window).on("load", function () {

    setTimeout(function () {

        equalheightrow(".recent-news-section .news-title-description");
        equalheightrow(".box-section .box-wrapper");
    }, 500);

});


jQuery(window).on("resize", function () {

    setTimeout(function () {
        equalheightrow(".recent-news-section .news-title-description");
        equalheightrow(".box-section .box-wrapper");
    }, 500);
});

jQuery(document).ready(function () {

    // Search bar in header section
    if (jQuery("#header-search-form").length > 0) {

        jQuery("[name=search-option]").on("click", function () {
            const search_option = jQuery(this).val();
            if (search_option == "website") {
                jQuery("#search-type-option").hide();
                jQuery(this).parents(".header-bottom-details").addClass("full-width");
            } else {
                jQuery("#search-type-option").show();
                jQuery(this).parents(".header-bottom-details").removeClass("full-width");
            }
        });

        jQuery("#header-search-form").on("submit", function (e) {

            const search_option = jQuery("[name=search-option]:checked").val();
            e.preventDefault();
            if (search_option == "catalog") {
                const search_url = jQuery("[name=search_url]").val();
                window.open(search_url.replace("[query]", encodeURIComponent(jQuery(".find-title").val())), "_blank");
            } else {
                window.open(`/?s=${encodeURIComponent(jQuery(".find-title").val())}`, "_self");
            }
        });
    }
});
