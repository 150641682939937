// JavaScript Document

// Scripts written by __gulp_init_author_name__ @ __gulp_init_author_company__

/**
 * Check if the alert exists
 */
const ALERT = document.querySelector(".thanksgivin-section");

if (ALERT) {
    /**
     * Open the alert immediately if it was not previously hidden
     */
    if (sessionStorage.getItem("alert-hidden") != "true") {
        ALERT.classList.remove("d-none");
    }

    /**
     * Find the alert button
     */
    const BUTTON = ALERT.querySelector(".btn-close");

    /**
     * On BUTTON click, remove ALERT `is-active` and store the interaction in sessionStorage
     */
    BUTTON.addEventListener("click", () => {
        ALERT.classList.add("d-none");
        sessionStorage.setItem("alert-hidden", "true");
    }, { passive: true });
}
